


















































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import AddNewUserModal from "@/components/modals/AddNewUserModal.vue";
import UserAccessRightsTableItem from "@/components/user-access-rights/UserAccessRightsTableItem.vue";
import ValidationWarningModal from "@/components/modals/ValidationWarningModal.vue";
import {mapActions, mapGetters} from "vuex";
import {UserData} from "@/types";
import router from "@/router";

export default Vue.extend({
  components: {
    Layout,
    "search-bar": SearchBar,
    "add-new-user-modal": AddNewUserModal,
    "user-access-rights-table-item": UserAccessRightsTableItem,
    "validation-warning-modal": ValidationWarningModal
  },

  data() {
    return {
      title: "User access rights",
      currentPage: 1,
      perPage: 10,
      userAuthorisationsVenues: [],
      activeFilter: false,
      randomId: Math.floor(Math.random() * 100000),
      userIsOwnerOfVenueOwner: false
    }
  },

  async created(): Promise<void> {
    await this.checkLoggedUserRolePermission({ permissionId: 11 });
    await this.checkLoggedUserRolePermission({permissionId: 12});
  },

  async mounted(): Promise<void> {
    await this.setUsers();
    this.fetchUsersImages();
  },

  computed: {
    ...mapGetters("userModule", {
      users: "GET_USERS",
      hasManageUserPermissions: "GET_MANAGE_USER_PERMISSION",
      hasManageRolePermissions: "GET_ROLE_MANAGEMENT_PERMISSION",
      getListUserRolePermission: "GET_LIST_OF_USERS_PERMISSION",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    totalUsers(): number {
      return this.users.length;
    },
    usersList(): Array<UserData> {
      this.users.map(user =>{
        user.roles.map((role,index) =>{
          if(role.id === 5 && user.roles.length > 1){
            user.roles.splice(index, 1)
          }
        })
      })
      return this.users.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    }
  },

  methods: {
    ...mapActions("userModule", {
      // setUsersSummary: "FETCH_USERS_ACCESS_RIGHTS_SUMMARY",
      setUserAuthorisations: "FETCH_USER_AUTHORISATIONS",
      setUsers: "FETCH_USERS",
      setUserRoles: "FETCH_USER_ROLES",
      filterActiveUsers: "FILTER_ACTIVE_USERS"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION"
    }),
    emitUsersImages(): void {
      this.currentPage = 1;
      this.fetchUsersImages();
    },
    fetchUsersImages(): void {
      for (let i = 0; i < this.users.length; i++) {
        const user: UserData = this.users[i];

        if (!!user.image_last_updated) {
          this.setImage({id: user.id, imageType: "clientzone_user", multi: true});
        }
      }
    },
    filterUserListByStatus(): void {
      this.currentPage = 1;
      this.filterActiveUsers(this.activeFilter)
    },
    isUserOwner(value: boolean): void {
      this.userIsOwnerOfVenueOwner = value;
    }
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.checkLoggedUserRolePermission({ permissionId: 11 });
          await this.checkLoggedUserRolePermission({permissionId: 12});
          await this.setUsers();
          this.fetchUsersImages();
        }
      }
    },
    userIsOwnerOfVenueOwner: {
      handler(value: boolean): void {
        if (value) {
          this.$bvModal.show("validation-warning-modal" + this.randomId);
          this.userIsOwnerOfVenueOwner = false;
        }
      }
    },
    getListUserRolePermission:{
      handler(value){
        if(!value){
          this.$router.push("/dashboard")
        }
      }
    }
  }
})
