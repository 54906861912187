



































































import Vue, {PropType} from "vue";
import {UserData} from "@/types";
import UserAuthVenues from "@/components/modals/UserAuthVenuesModal.vue";
import DeleteUserConfirmModal from "@/components/modals/DeleteUserConfirmModal.vue";
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "user-access-rights-table-item",

  components: {
    "user-auth-venues": UserAuthVenues,
    "delete-user-confirm-modal": DeleteUserConfirmModal
  },

  props: {
    user: Object as PropType<UserData>,
    hasPermissions: Boolean as PropType<boolean>,
    hasManageRolePermissions: Boolean as PropType<boolean>
  },

  computed: {
    ...mapGetters("userModule", {
      userVenuesAuthorisations: "GET_USER_VENUES_AUTHORISATIONS"
    }),
    insertVenueImage(): string {
      if (!this.user.image) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${this.user.image}`
    },
  },

  methods: {
    userRoleClass(id: number): string {
      if (id === 1) return "admin";
      if (id === 2) return "venue-manager";
      if (id === 3) return "marketer";
      if (id === 4) return "accountant";
      if (id === 5) return "unassigned";
    },
    userRoleName(value: string): string {
      if (value === "Admin") return this.$t("Admin");
      if (value === "Venue Manager") return this.$t("Venue Manager");
      if (value === "Marketer") return this.$t("Marketer");
      if (value === "Accountant") return this.$t("Accountant");
      if (value === "User") return this.$t("Unassigned");
    },
    goToEditUserPermissions(): void {
      this.$router.push({
        path: '/edit-user-permissions/' + this.user.id,
        params: { id: this.user.id }
      })
    },
    openDeleteUserModal(userId: number): void {
      const validateOwner: boolean = !!this.userVenuesAuthorisations.filter(u => u.czone_user_id === userId).find(v => v.venue_id === null && v.role_id === 1);
      this.$emit("check", validateOwner);

      if (!validateOwner) this.$bvModal.show("delete-user-confirm-modal" + userId);
    }
  }
})
