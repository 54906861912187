






































import Vue, {PropType} from "vue";
import {mapActions} from "vuex";
import router from '@/router';

export default Vue.extend({
  name: "delete-user-confirm-modal" as string,

  props: {
    userId: Number as PropType<number>,
    userEmail: String as PropType<string>
  },

  methods: {
    ...mapActions("userModule", {
      deleteUser: "DELETE_USER"
    }),
    async removeUser(): Promise<void> {
      await this.deleteUser(this.userId);
      this.$bvModal.hide("delete-user-confirm-modal" + this.modalId);
    }
  }
})
