
































import Vue, {PropType} from "vue";
import {getUserRole} from "@/utils/helpers";
import {VenueData} from "@/types";

export default Vue.extend({
  name: "user-auth-venues-modal" as string,

  props: {
    modalId: Number as PropType<number>,
    data: Array as PropType<Array<VenueData>>
  },

  data() {
    return {
      title: "User's authorisations to venues",
      currentPage: 1,
      perPage: 28
    }
  },

  computed: {
    totalUserAuthorisationsVenues(): number {
      // return this.data.length;
      return this.data.filter((v) => v.id).length
    },
    userAuthorisationsVenuesList(): Array<VenueData> {
      return this.data.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    }
  },

  methods: {
    userRole(id: number): string {
      return getUserRole(id);
    },
    userRoleClass(id: number): string {
      if (id === 1) return "admin";
      if (id === 2) return "venue-manager";
      if (id === 3) return "marketer";
      if (id === 4) return "accountant";
    },
    userRoleName(value: string): string {
      if (value === "Owner") return "Admin";
      if (value === "Admin") return "Venue manager";
      if (value === "Marketer") return "Marketer";
      if (value === "Accountant") return "Accountant";
    }
  }
})
